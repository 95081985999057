import React, { useEffect } from "react";
import styled from "@emotion/styled/macro";

const Modal = ({ className, children, show, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = show ? "hidden" : "";
  }, [show]);

  return (
    show && (
      <div className={`${className}`}>
        <div className="modal-close-button" onClick={onClose}>
          <div>&#120;</div>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    )
  );
};

const StyledModal = styled(Modal)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 1;

  .modal-content {
    padding: 2rem;
    background-color: white;
    min-height: 500px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
  .modal-close-button {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: white;
    font-size: 2rem;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    transition: 0.5s;
    &:hover {
      cursor: pointer;
      background-color: white;
      color: black;
      border-radius: 0px;
    }
  }
`;

export default StyledModal;
