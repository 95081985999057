import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import styled from "@emotion/styled/macro";

import LoadingSpinner from "src/design-system/atoms/loading-spinner";
import LoadingRow from "src/design-system/components/loading-row/loading-row";

import { StyledReviewControl as ReviewControl } from "src/design-system/components/review/review-control/review-control";
import DataCard from "src/design-system/components/data-card/data-card";
import Textarea from "src/design-system/atoms/textarea";

const EmailEntrySelection = ({ className, status, profileData, onCancel, onComplete }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving] = useState(false);

  const [emailOptions, setEmailOptions] = useState([]);
  const [selectedEmailOption, setSelectedEmailOption] = useState({
    id: null,
    name: null,
    message: null,
    isCustom: false,
  });
  const [customEmailResponse, setCustomEmailResponse] = useState(null);

  let isValidCustomResponse =
    selectedEmailOption.isCustom &&
    customEmailResponse !== null &&
    customEmailResponse !== "";
  let canComplete =
    (selectedEmailOption.id !== null && !selectedEmailOption.isCustom) ||
    isValidCustomResponse;

  const getEmailOptionsAPI = async () => {
    const params = {
      usage: "associatesRejection",
      language: profileData.language,
    };
    // GET email options
    const response = await API.get("associates-surveys-email-options", "/", {
      queryStringParameters: params,
    });
    let allOptions = response.email_options.sort((a, b) => a - b);
    let customOptions = [];
    let standardOptions = [];

    for (let i = 0; i < allOptions.length; i++) {
      let option = allOptions[i];
      if (option.isCustom) {
        customOptions.push(option);
      } else {
        standardOptions.push(option);
      }
    }
    setEmailOptions(standardOptions.concat(customOptions));
    setIsLoading(false);
  };

  const onContinue = () => {
    onComplete(selectedEmailOption.id, customEmailResponse);
  };
  // On modal render, fetch the email options
  useEffect(() => {
    getEmailOptionsAPI();
  }, []);

  return (
    <div data-testid="email-entry-selection" className={`${className}`}>
      {isLoading ? (
        <div className="loading-placeholder">
          <h1>Loading email options</h1>
          <p />
          <LoadingSpinner />
        </div>
      ) : (
        <div>
          <h2>Select unapproval reason</h2>
          <p>
            Please select the reason for unapproving. This will be sent as an
            email to the applicant.
          </p>
          <div className="options">
            <ul>
              {emailOptions.map((emailOption, index) => (
                <li key={index}>
                  <DataCard
                    data={[
                      [
                        {
                          label: "Reason",
                          value: emailOption.name,
                          type: "text",
                        },
                        {
                          label: "Message",
                          value: emailOption.message,
                          type: "text",
                        },
                      ],
                    ]}
                    selected={selectedEmailOption.id === emailOption.id}
                    onClick={() => setSelectedEmailOption(emailOption)}
                    className={className}
                  />
                  {emailOption.isCustom &&
                  selectedEmailOption.id === emailOption.id ? (
                    <div className="custom-email-response">
                      <Textarea
                        placeholder="Enter a custom response"
                        text={customEmailResponse}
                        onChange={(e) => setCustomEmailResponse(e.target.value)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <LoadingRow isLoading={isSaving}>
            <ReviewControl
              onCancel={onCancel}
              onSaveComplete={onContinue}
              disableSaveComplete={isLoading || !canComplete}
              status={status}
            />
          </LoadingRow>
        </div>
      )}
    </div>
  );
};

const StyledEmailEntrySelection = styled(EmailEntrySelection)`
  .loading-placeholder {
    width: 20rem;
    margin-left: auto;
    margin-right: auto;
  }

  .info-text {
    margin-left: 2rem;
    li {
      margin-bottom: 0.25rem;
    }
  }
  .options {
    margin: 1rem 0;
    ul {
      list-style: none;
      li {
        background-color: white;
        margin-bottom: 0.5rem;
      }
    }
  }
  h3 {
    margin-bottom: 1rem;
  }
  .custom-email-response {
    margin-bottom: 1rem;
    textarea {
      width: 100%;
      border: 1px solid grey;
      border-radius: 0.25rem;
      padding: 0.5rem;
      font-size: 1rem;
      &:focus {
        outline: none;
      }
    }
  }
`;

export default StyledEmailEntrySelection;
