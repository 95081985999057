import React, { useEffect, useState } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Layout from "src/design-system/layouts/layout";
import { API } from "aws-amplify";

import Button from "src/design-system/atoms/button";
import SurveyList from "src/design-system/components/survey-list/survey-list";
import AssociateProfile from "src/design-system/blocks/associates-profile/associate-profile";
import OverviewSurveyHeader from "src/design-system/components/review/associates/overview-survey-header/overview-survey-header";
import OverviewSurveyRow from "src/design-system/components/review/associates/overview-survey-row/overview-survey-row";
import ReviewDropdown from "src/design-system/components/review/fields/review-dropdown/review-dropdown";
import {
  associatesDropdownFilterOptions,
  associatesDropdownLanguageOptions,
  associatesDropdownFromTypeOptions,
  associatesDropdownHQRegion,
  associatesSortByOptions,
} from "src/design-system/components/review/fields/review-dropdown/dropdown-options.data";
import vars from "src/design-system/variables";
import styled from "@emotion/styled/macro";

const ReviewPage = ({ className }) => {
  const [surveys, setSurveys] = useState([]);
  const [pageKey, setPageKey] = useState(null);
  const [apiParams, setApiParams] = useState({
    limit: 30,
    is_reviewed: false,
    final_decision: "unreviewed",
    last_evaluated_key: null,
  });
  const [hasMoreSurveys, setHasMoreSurveys] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSurveyResponseId, setSelectedSurveyResponseId] =
    useState(null);
  const [sidebarIsVisible, setSidebarIsVisible] = useState(true);
  const [sortByField, setSortByField] = useState({
    field: "recordedDate",
    isAsc: false,
  });
  const [filterByReviewState, setFilterByReviewState] = useState("unreviewed");
  const [filterByLanguage, setFilterByLanguage] = useState("all");
  const [filterByFormType, setFilterByFormType] = useState("all");
  const [filterByHQRegion, setFilterByHQRegion] = useState("all");
  let selectedSurvey = surveys.find((survey) => {
    return survey.responseId === selectedSurveyResponseId;
  });

  const getApiData = async (clearData = true) => {
    setIsLoading(true);
    setSelectedSurveyResponseId(null);
    let atLimit = false;
    let tmpSurveys = [...surveys];
    let tmpPageKey = pageKey;

    if (clearData) {
      tmpSurveys = [];
      tmpPageKey = null;
    }
    while (!atLimit) {
      const params = apiParams;
      if (tmpPageKey) {
        params.last_evaluated_key = JSON.stringify(tmpPageKey);
      } else {
        delete params.last_evaluated_key;
      }
      Object.keys(params).forEach(
        (key) => params[key] === undefined && delete params[key]
      );
      await API.get("associates-surveys", "/", {
        queryStringParameters: params,
      }).then((res) => {
        console.log("Fetching surveys");
        tmpSurveys = tmpSurveys.concat(res.surveys);
        tmpPageKey = res.last_evaluated_key;
        atLimit = tmpSurveys.length >= params.limit || tmpPageKey === null;
      });
    }
    setSurveys([...tmpSurveys]);
    if (tmpSurveys.length > 0) {
      setSelectedSurveyResponseId(tmpSurveys[0].responseId);
    }
    setPageKey(tmpPageKey);
    setHasMoreSurveys(tmpPageKey === null);
    setIsLoading(false);
  };
  useEffect(() => {
    getApiData();
  }, []);
  const loadMore = () => {
    if (pageKey) {
      getApiData(false);
    }
  };
  useEffect(() => {
    setApiParams((params) => ({
      ...params,
      is_reviewed:
        filterByReviewState !== "all"
          ? filterByReviewState === "unapproved" ||
            filterByReviewState === "approved"
          : undefined,
      final_decision:
        filterByReviewState !== "all" ? filterByReviewState : undefined,
      language: filterByLanguage !== "all" ? filterByLanguage : undefined,
      associate_type: filterByFormType !== "all" ? filterByFormType : undefined,
      region: filterByHQRegion !== "all" ? filterByHQRegion : undefined,
    }));
  }, [
    filterByReviewState,
    filterByLanguage,
    filterByFormType,
    filterByHQRegion,
  ]);
  // For sorting to work on chrome, each return has to return an integer not boolean
  const surveyComparator = (a, b) => {
    if (sortByField.field.includes(".")) {
      const levels = sortByField.field.split(".");
      const topLevel = levels[0];
      const nestedLevel = levels[1];
      return sortByField.isAsc
        ? a[topLevel][nestedLevel] >= b[topLevel][nestedLevel]
          ? 1
          : -1
        : a[topLevel][nestedLevel] <= b[topLevel][nestedLevel]
        ? 1
        : -1;
    } else {
      return sortByField.isAsc
        ? a[sortByField.field] >= b[sortByField.field]
          ? 1
          : -1
        : a[sortByField.field] <= b[sortByField.field]
        ? 1
        : -1;
    }
  };
  return (
    <Layout>
      <main className={className}>
        <title>Review | Associates</title>
        <h1>Review Associates</h1>
        <p className="intro-text">
          View and review pending associates that have completed the application
          form on the EMF website
        </p>
        <div className="data-controls">
          <div>Filter by</div>
        </div>
        <div className="data-controls">
          <div>
            Status{" "}
            <ReviewDropdown
              dataTestId="review-page-filter-status"
              selected={filterByReviewState}
              onChange={(e) => setFilterByReviewState(e.target.value)}
              options={associatesDropdownFilterOptions}
            />
          </div>
          <div>
            Language{" "}
            <ReviewDropdown
              dataTestId="review-page-filter-language"
              selected={filterByLanguage}
              onChange={(e) => setFilterByLanguage(e.target.value)}
              options={associatesDropdownLanguageOptions}
            />
          </div>
          <div>
            Form Type{" "}
            <ReviewDropdown
              dataTestId="review-page-filter-type"
              selected={filterByFormType}
              onChange={(e) => setFilterByFormType(e.target.value)}
              options={associatesDropdownFromTypeOptions}
            />
          </div>
          <div>
            HQ Region{" "}
            <ReviewDropdown
              dataTestId="review-page-filter-hqregion"
              selected={filterByHQRegion}
              onChange={(e) => setFilterByHQRegion(e.target.value)}
              options={associatesDropdownHQRegion}
            />
          </div>
          <div>
            <Button
              dataTestId="review-page-filter-apply"
              onClick={() => getApiData()}
            >
              Apply filters
            </Button>
          </div>
        </div>
        <div className="data-controls">
          <div>
            Sort by{" "}
            <ReviewDropdown
              selected={sortByField.field}
              onChange={(e) =>
                setSortByField({
                  field: e.target.value,
                  isAsc: sortByField.isAsc,
                })
              }
              options={associatesSortByOptions}
            />
          </div>
          <div
            className="data-order"
            onClick={() =>
              setSortByField({
                field: sortByField.field,
                isAsc: !sortByField.isAsc,
              })
            }
          >
            {sortByField.isAsc ? <>&#8593;</> : <>&#8595;</>}
          </div>
        </div>
        <div
          onClick={() => setSidebarIsVisible(!sidebarIsVisible)}
          tabIndex="1"
          role="button"
          className="toggle-sidebar"
        >
          {sidebarIsVisible ? <>&#8592; Hide</> : <>&#8594; Show</>} sidebar
        </div>
        <div className={`review-inbox ${!sidebarIsVisible ? "is-hidden" : ""}`}>
          <div className="survey-sidebar">
            <SurveyList
              surveys={surveys}
              sortBy={surveyComparator}
              sortByField={sortByField}
              SurveyHeader={OverviewSurveyHeader}
              SurveyItem={OverviewSurveyRow}
              selectedSurveyResponseId={selectedSurveyResponseId}
              setSelectedSurveyResponseId={setSelectedSurveyResponseId}
              loadMoreHandle={loadMore}
              hasMoreSurveys={hasMoreSurveys}
              isLoading={isLoading}
            />
          </div>
          {selectedSurveyResponseId ? (
            <AssociateProfile
              key={selectedSurveyResponseId}
              hasControls={
                selectedSurvey ? !selectedSurvey.reviewFields.isReviewed : false
              }
              associate={selectedSurvey ? selectedSurvey : null}
              updateData={getApiData}
            />
          ) : null}
        </div>
      </main>
    </Layout>
  );
};
const StyledReviewPage = styled(ReviewPage)`
  .review-inbox {
    display: grid;
    grid-template-columns: 500px auto;
    grid-gap: 0.5rem;
    transition: 0.5s;
  }
  .survey-sidebar {
    ${SurveyList} {
      padding: 0;
      margin: 0;
      .items {
        height: 900px;
        overflow-x: hidden;
        overflow-y: scroll;
      }
      .row-item:not(.selected):hover .data-row-wrap {
        cursor: pointer;
        background-color: ${vars.gandalfGrey};
      }
    }
  }
  .review-inbox.is-hidden {
    grid-template-columns: 0 auto;
  }
  .toggle-sidebar {
    margin-bottom: 1rem;
    border-bottom: 1px dotted grey;
    font-weight: normal;
    color: ${vars.plasticsBlue};
    transition: 0.5s;
    user-select: none;
  }
  .toggle-sidebar:hover {
    color: ${vars.emfBlue};
    cursor: pointer;
    transition: 0.5s;
  }
  .data-controls {
    margin: 1.5rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 75%;
    div {
      margin-right: 1rem;
      display: flex;
      flex-direction: column;
    }
    select,
    button {
      font-size: 1rem;
    }
    button {
      background-color: ${vars.anduinBlue};
      color: white;
      height: 3rem;
    }
    .data-order {
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${vars.gandalfGrey};
    }
    .data-order:hover {
      cursor: pointer;
      user-select: none;
      background-color: ${vars.anduinBlue};
      color: white;
    }
  }
`;
export default withAuthenticator(StyledReviewPage);
