import React from "react";
import styled from "@emotion/styled/macro";

import DataCard from "src/design-system/components/data-card/data-card";

const SalesforceOrganisation = ({ className, org, selected, setSelected }) => {
  // Attempt at holding format in data instead of divs. List of columns, with each column having a list of fields
  const formattedOrg = [
    [
      {
        title: "Name",
        value: org.Name,
        type: "text",
      },
      {
        title: "Network Tier",
        value: org.Network_Tier__c,
        type: "text",
      },
      {
        title: "Type",
        value: org.Type,
        type: "text",
      },
      {
        title: "Website",
        value: org.Website,
        type: "url",
      },
    ],
    [
      {
        title: "Headquarters",
        value: org.HQ_Registered_Country__c,
        type: "text",
      },
      {
        title: "Activity regions",
        value: org.Regions_of_activity__c,
        type: "text",
      },
      {
        title: "Industry Classification",
        value: org.Industry_Classification__c,
        type: "text",
      },
      {
        title: "Salesforce",
        display: "View",
        value: `${process.env.GATSBY_SALESFORCE_URL}/lightning/r/Account/${org.Id}/view`,
        type: "url",
      },
    ],
    [
      {
        title: "Duplicate confidence",
        value: org.matchCertainty + "%",
        type: "text",
      },
    ],
  ];
  return (
    <DataCard
      data={formattedOrg}
      selected={selected}
      onClick={() => setSelected(org.Id)}
      className={className}
    />
  );
};

const StyledSalesforceOrganisation = styled(SalesforceOrganisation)``;

export default StyledSalesforceOrganisation;
