import React from "react";
import styled from "@emotion/styled/macro";

import DataCard from "src/design-system/components/data-card/data-card";

const SalesforceContact = ({ className, contact, selected, setSelected }) => {
  // Attempt at holding format in data instead of divs. List of columns, with each column having a list of fields
  const formattedContact = [
    [
      {
        title: "Name",
        value: contact.FirstName + " " + contact.LastName,
        type: "text",
      },
      {
        title: "Email",
        value: contact.Email,
        type: "text",
      },
    ],
    [
      {
        title: "Job Title",
        value: contact.Title,
        type: "text",
      },
      {
        title: "Department",
        value: contact.Department,
        type: "text",
      },
      {
        title: "Salesforce",
        display: "View",
        value: `${process.env.GATSBY_SALESFORCE_URL}/lightning/r/Contact/${contact.Id}/view`,
        type: "url",
      },
    ],
    [
      {
        title: "Duplicate confidence",
        value: contact.matchCertainty + "%",
        type: "text",
      },
    ],
  ];

  return (
    <DataCard
      data={formattedContact}
      selected={selected}
      onClick={() => setSelected(contact.Id)}
      className={className}
    />
  );
};

const StyledSalesforceContact = styled(SalesforceContact)``;

export default StyledSalesforceContact;
