import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import vars from "src/design-system/variables";
import styled from "@emotion/styled/macro";
import { formatDate } from "src/utils/dateHelpers";

import Modal from "src/design-system/components/modal/modal";
import Textarea from "src/design-system/atoms/textarea";
import TooltipText from "src/design-system/atoms/tooltipText";
import LoadingRow from "src/design-system/components/loading-row/loading-row";

import ReviewDropdown from "src/design-system/components/review/fields/review-dropdown/review-dropdown";
import { StyledReviewControl as ReviewControl } from "src/design-system/components/review/review-control/review-control";
import { associatesReviewOptions } from "src/design-system/components/review/fields/review-dropdown/dropdown-options.data";
import SalesforceEntrySelection from "src/design-system/components/salesforce-entry-selection/salesforce-entry-selection";
import EmailEntrySelection from "src/design-system/components/email-entry-selection/email-entry-selection";

const AssociateProfile = ({
  className,
  associate,
  hasControls,
  updateData,
}) => {
  const [profileData, setProfileData] = useState(associate);
  const [status, setStatus] = useState({ type: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();
  const [showSalesforceModal, setShowSalesforceModal] = useState(false);
  const [showEmailRejectionModal, setShowEmailRejectionModal] = useState(false);

  useEffect(() => {
    loadUserInfo();
  }, []);

  const loadUserInfo = async () => {
    const userInfo = await Auth.currentUserInfo();
    setUser(userInfo);
  };

  const saveButtonHandler = async () => {
    await saveHandler(false);
  };

  const saveCompleteButtonHandler = async () => {
    // First save the data to the API in case completion fails
    await saveHandler();
    const isValid = validateSurvey();
    const isApproved = isFlagApproved(profileData.reviewFields.finalDecision);
    if (isValid) {
      if (isApproved) {
        // Initiate acceptance flow
        setShowSalesforceModal(true);
      } else {
        // Initiate rejection flow
        setShowEmailRejectionModal(true);
      }
    }
  };

  const saveHandler = (isReviewed = false) => {
    // Calls API & updates local & parent state
    return saveToAPI({
      responseId: profileData.responseId,
      dueDiligence: profileData.reviewFields.dueDiligence,
      reputationalDiligence: profileData.reviewFields.reputationalDiligence,
      publicProfile: profileData.reviewFields.publicProfile,
      finalDecision: profileData.reviewFields.finalDecision,
      commentary: profileData.reviewFields.commentary,
      isReviewed,
    })
      .then(() => {
        setProfileData({
          ...profileData,
          reviewFields: {
            ...profileData.reviewFields,
            reviewer: user.attributes.email,
          },
        });
        setStatus({ type: "success", message: "Saved progress" });
      })
      .catch((err) => {
        try {
          const errMessage =
            err.response.data.response ?? err.response.data.error;
          console.error(errMessage);
          setStatus({ type: "error", message: errMessage });
        } catch {
          console.error(err);
        }
        setIsLoading(false);
      });
  };
  const completeEmailFlowHandler = async (
    emailOptionId,
    customEmailResponse
  ) => {
    setShowEmailRejectionModal(false);
    const payload = {
      responseId: profileData.responseId,
      emailOptionId: JSON.stringify(emailOptionId),
      finalDecision: profileData.reviewFields.finalDecision,
      isReviewed: true,
    };
    if (customEmailResponse !== null && customEmailResponse !== "") {
      payload["customEmailMessage"] = customEmailResponse;
    }
    await saveToAPI(payload);
    await updateData();
  };

  const cancelButtonHandler = () => {
    setProfileData(associate);
    setStatus({ type: "success", message: "Cancelled changes" });
  };

  const saveToAPI = async (payload) => {
    await loadUserInfo();
    payload["reviewer"] = user.attributes.email;

    setIsLoading(true);
    // Commit changes to the database
    await API.post("associates-surveys", "/", { body: payload });
    setIsLoading(false);
  };

  const completeSalesforceFlowHandler = async () => {
    setShowSalesforceModal(false);
    await saveHandler(true);
    await updateData();
  };

  const updateState = (e, field) => {
    const { value } = e.target;
    setProfileData({
      ...profileData,
      reviewFields: { ...profileData.reviewFields, [field]: value },
    });
  };

  const isFlagApproved = (flag) => {
    return flag === "approved";
  };
  const isFlagUnapproved = (flag) => {
    return flag === "unapproved";
  };
  const isFlagCompleted = (flag) => {
    return isFlagApproved(flag) || flag === "unapproved";
  };

  const validateSurvey = () => {
    // Check that all flags are either approved or unapproved
    const dueDiligenceChecked = isFlagCompleted(
      profileData.reviewFields.dueDiligence
    );
    const reputationalDiligenceChecked = isFlagCompleted(
      profileData.reviewFields.reputationalDiligence
    );
    const publicProfileChecked = isFlagCompleted(
      profileData.reviewFields.publicProfile
    );
    const finalDecisionChecked = isFlagCompleted(
      profileData.reviewFields.finalDecision
    );

    // Check if flags are approved
    const dueDiligenceApproved = isFlagApproved(
      profileData.reviewFields.dueDiligence
    );
    const reputationalDiligenceApproved = isFlagApproved(
      profileData.reviewFields.reputationalDiligence
    );
    const finalDecisionApproved = isFlagApproved(
      profileData.reviewFields.finalDecision
    );

    // Can complete if final decision is approved AND flags are approved
    // or if final decision is unapproved, flag states do not matter
    // (Fd AND (Dd AND Rd)) OR NOT Fd
    const isValidForApproval =
      (finalDecisionApproved &&
        dueDiligenceApproved &&
        reputationalDiligenceApproved) ||
      !finalDecisionApproved;
    const isValidForCompletion =
      dueDiligenceChecked &&
      reputationalDiligenceChecked &&
      (publicProfileChecked || profileData.associateType !== "startup") &&
      finalDecisionChecked &&
      isValidForApproval;

    if (!dueDiligenceChecked) {
      setStatus({
        type: "error",
        message:
          "You cannot complete this survey as due diligence is unreviewed or pending",
      });
    } else if (!reputationalDiligenceChecked) {
      setStatus({
        type: "error",
        message:
          "You cannot complete this survey as reputational diligence is unreviewed or pending",
      });
    } else if (
      !publicProfileChecked &&
      profileData.associateType === "startup"
    ) {
      setStatus({
        type: "error",
        message:
          "You cannot complete this survey as public profile is unreviewed or pending",
      });
    } else if (!finalDecisionChecked) {
      setStatus({
        type: "error",
        message:
          "You cannot complete this survey as the final decision is unreviewed or pending",
      });
    } else if (finalDecisionApproved && !isValidForApproval) {
      if (!dueDiligenceApproved) {
        setStatus({
          type: "error",
          message:
            "You cannot approve this survey as due diligence is unapproved",
        });
      } else if (!reputationalDiligenceApproved) {
        setStatus({
          type: "error",
          message:
            "You cannot approve this survey as reputational diligence is unapproved",
        });
      } else if (isValidForApproval) {
        setStatus({ type: "error", message: "" });
      }
    } else if (isValidForCompletion) {
      setStatus({ type: "", message: "" });
    }

    return isValidForCompletion;
  };

  return (
    <section className={className}>
      <h2>Applicant Information</h2>
      {profileData ? (
        <>
          <div className="meta-info">
            <div>
              <span className="label">Language: </span>
              {profileData.language ?? "EN"}
            </div>
            <div>
              <span className="label">Response ID: </span>
              {profileData.responseId}
            </div>
            <div>
              <span className="label">EMF Org ID: </span>
              {profileData.emfOrgId}
            </div>
          </div>
          <article className="survey-details">
            <div>
              <h4>Organisation Info</h4>
              <div className="survey-detail survey-detail-grid">
                <div className="label">Name</div>
                <div>{profileData.companyName}</div>
                <div className="label">Registration No.</div>
                <div>{profileData.companyRegistrationNumber}</div>
                <div className="label">Industry</div>
                <div>{profileData.industryClassification}</div>
                <div className="label">Website</div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    profileData.websiteUrl.includes("http")
                      ? profileData.websiteUrl
                      : "https://" + profileData.websiteUrl
                  }
                  rel="noopener"
                >
                  {profileData.websiteUrl}
                </a>
                {profileData.associateType === "startup" ||
                profileData.associateType === "business" ? (
                  <>
                    <div className="label">Revenue</div>
                    <div>{profileData.annualRevenue}</div>
                  </>
                ) : null}
              </div>
            </div>
            <div>
              <h4>Contact Info</h4>
              <div className="survey-detail survey-detail-grid">
                <div className="label">Name</div>
                <div>
                  {profileData.firstName} {profileData.lastName}
                </div>
                <div className="label">Email</div>
                <div>{profileData.businessEmail}</div>
                <div className="label">Role</div>
                <div>{profileData.jobRole}</div>
                <div className="label">Department</div>
                <div>{profileData.department}</div>
              </div>
            </div>
            <div>
              <h4>Organisation Introduction</h4>
              <div className="survey-detail survey-detail-text">
                <div>{profileData.companyDescription}</div>
              </div>
            </div>
            <div>
              <h4>CE Activities</h4>
              <div className="survey-detail survey-detail-text">
                <div>{profileData.circularEconomyActivityDescription}</div>
              </div>
            </div>
            <div>
              <h4>Review</h4>
              <div className="survey-detail survey-detail-grid wide-label">
                <TooltipText
                  labelText="Due Diligence"
                  tooltipText="Website link works and website matches org. registered.
                        Email address matches org. registered.
                        Company Registration Number is submitted"
                />
                <ReviewDropdown
                  dataTestId="review-dropdown-dueDiligence"
                  isDisabled={!hasControls}
                  onChange={(e) => updateState(e, "dueDiligence")}
                  selected={profileData.reviewFields.dueDiligence}
                  options={associatesReviewOptions}
                />
                <TooltipText
                  labelText="Reputational Diligence"
                  tooltipText="'Approve' = no reputational concerns regarding sector / practice OR if sign off given by Reputational Risk team.
                        'Pending' = awaiting decision from Reputational Risk.
                        'Unapproved' = decision by Reputation Risk is to reject application."
                />
                <ReviewDropdown
                  dataTestId="review-dropdown-reputationalDiligence"
                  isDisabled={!hasControls}
                  onChange={(e) => updateState(e, "reputationalDiligence")}
                  selected={profileData.reviewFields.reputationalDiligence}
                  options={associatesReviewOptions}
                />
                {profileData.associateType === "startup" ? (
                  <>
                    <TooltipText
                      labelText="Public Profile"
                      tooltipText="Used to highlight organisations that would be displayed on our website"
                    />
                    <ReviewDropdown
                      dataTestId="review-dropdown-publicProfile"
                      isDisabled={!hasControls}
                      onChange={(e) => updateState(e, "publicProfile")}
                      selected={profileData.reviewFields.publicProfile}
                      data-testid="external-db-dropdown"
                      options={associatesReviewOptions}
                    />
                  </>
                ) : (
                  ""
                )}
                <TooltipText
                  labelText="Final Decision"
                  tooltipText="In order to 'Save and Complete', either the 'Approve' flag or 'Unapprove' flag needs to be selected (in addition to all other flags)"
                />
                <ReviewDropdown
                  dataTestId="review-dropdown-finalDecision"
                  isDisabled={!hasControls}
                  onChange={(e) => updateState(e, "finalDecision")}
                  selected={profileData.reviewFields.finalDecision}
                  options={associatesReviewOptions}
                />
                {isFlagUnapproved(profileData.reviewFields.finalDecision) &&
                profileData.reviewFields.isReviewed ? (
                  <>
                    <div className="label">Rejection reason</div>
                    <div>{profileData.reviewFields.emailOptionId}</div>
                  </>
                ) : null}
                <div className="label">Last reviewed by</div>
                <div>
                  {profileData.reviewFields.reviewer !== ""
                    ? profileData.reviewFields.reviewer
                    : "None"}
                </div>
                <div className="label">Last edit</div>
                <div>
                  {profileData.reviewFields.dateOfLastEdit !== ""
                    ? formatDate(profileData.reviewFields.dateOfLastEdit)
                    : "None"}
                </div>
                <div className="label">Saved to salesforce</div>
                <div>
                  {profileData.salesforceData.dateOfEntry !== ""
                    ? formatDate(profileData.salesforceData.dateOfEntry)
                    : "No"}
                </div>
                {profileData.salesforceData.dateOfEntry !== "" ? (
                  <>
                    <div className="label">Salesforce Organisation</div>
                    {profileData.salesforceData.organisationId !== "" ? (
                      <a
                        href={`${process.env.GATSBY_SALESFORCE_URL}/lightning/r/Account/${profileData.salesforceData.organisationId}/view`}
                        target="_blank"
                        rel="noopener"
                      >
                        View in Salesforce
                      </a>
                    ) : (
                      "Link unavailable"
                    )}
                    <div className="label">Salesforce Contact</div>
                    {profileData.salesforceData.contactId !== "" ? (
                      <a
                        href={`${process.env.GATSBY_SALESFORCE_URL}/lightning/r/Contact/${profileData.salesforceData.contactId}/view`}
                        target="_blank"
                        rel="noopener"
                      >
                        View in Salesforce
                      </a>
                    ) : (
                      "Link unavailable"
                    )}
                  </>
                ) : null}
              </div>
            </div>
            <div>
              <h4>Reviewer Comments</h4>
              <Textarea
                dataTestId="reviewer-comments"
                isDisabled={!hasControls}
                onChange={(e) => updateState(e, "commentary")}
                height={200}
                text={profileData.reviewFields.commentary}
                className="survey-detail survey-detail-text"
              />
            </div>
            <Modal
              show={showSalesforceModal}
              onClose={() => setShowSalesforceModal(false)}
            >
              <SalesforceEntrySelection
                status={status}
                profileData={profileData}
                onCancel={() => setShowSalesforceModal(false)}
                onComplete={completeSalesforceFlowHandler}
              />
            </Modal>
            <Modal
              show={showEmailRejectionModal}
              onClose={() => setShowEmailRejectionModal(false)}
            >
              <EmailEntrySelection
                status={status}
                profileData={profileData}
                onCancel={() => setShowEmailRejectionModal(false)}
                onComplete={completeEmailFlowHandler}
              />
            </Modal>
          </article>
        </>
      ) : (
        ""
      )}
      <LoadingRow isLoading={isLoading}>
        {hasControls && profileData ? (
          <ReviewControl
            onCancel={cancelButtonHandler}
            onSave={saveButtonHandler}
            onSaveComplete={saveCompleteButtonHandler}
            status={status}
          />
        ) : (
          ""
        )}
      </LoadingRow>
    </section>
  );
};
const StyledAssociateProfile = styled(AssociateProfile)`
  padding: 1rem;
  background-color: ${vars.barrowWightGrey};
  .meta-info {
    font-size: 0.75rem;
    display: grid;
    grid-gap: 0;
    grid-template-columns: auto 18rem auto;
    margin: 0.5rem 0;
    .label {
      font-size: 0.75rem;
    }
  }
  .survey-details {
    display: grid;
    grid-gap: 1rem;
    margin-bottom: 1rem;
  }
  .survey-details h4 {
    margin-bottom: 0.5rem;
  }
  .survey-detail {
    padding: 0.5rem;
    background-color: white;
    border-radius: 5px;
    word-break: break-word;
  }
  .survey-detail-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 30% auto;
  }
  .survey-detail-grid.wide-label {
    grid-template-columns: 50% auto;
  }
  .survey-detail-text {
    min-height: 200px;
  }
  @media screen and (min-width: ${vars.largeDesktopSize}) {
    .survey-details {
      grid-template-columns: 50% auto;
    }
  }
`;
export default StyledAssociateProfile;
