import React from "react";
import DataRow from "src/design-system/components/data-row/data-row";
import vars from "src/design-system/variables";

const OverviewSurveyHeader = () => (
  <DataRow isHeader columnWidths={vars.reviewAssociateColumnWidths}>
    <div>Name</div>
    <div>Type</div>
    <div>Submitted</div>
  </DataRow>
);

export default OverviewSurveyHeader;
