import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import styled from "@emotion/styled/macro";
import vars from "src/design-system/variables";

import SalesforceOrganisation from "src/design-system/components/salesforce-organisation/salesforce-organisation";
import SalesforceContact from "src/design-system/components/salesforce-contact/salesforce-contact";
import DataCard from "src/design-system/components/data-card/data-card";

import { StyledReviewControl as ReviewControl } from "src/design-system/components/review/review-control/review-control";
import LoadingSpinner from "src/design-system/atoms/loading-spinner";
import LoadingRow from "src/design-system/components/loading-row/loading-row";
import Button from "src/design-system/atoms/button";

const SalesforceEntrySelection = ({
  className,
  status,
  profileData,
  onCancel,
  onComplete,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [salesforceResults, setSalesforceResults] = useState([]);
  const [isErrored, setIsErrored] = useState(false);

  const [createNewOrg, setCreateNewOrg] = useState(false);
  const [createNewContact, setCreateNewContact] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [selectedContactId, setSelectedContactId] = useState(null);

  const [organisationDuplicates, setOrganisationDuplicates] = useState([]);
  const [contactDuplicates, setContactDuplicates] = useState([]);

  const [allResolved, setAllResolved] = useState(false);

  let canComplete =
    (selectedOrgId !== null ||
      createNewOrg ||
      organisationDuplicates.length === 0) &&
    (selectedContactId !== null ||
      createNewContact ||
      contactDuplicates.length === 0);

  const createSalesforceObjects = async () => {
    setIsLoading(true);
    const requests = [];
    requests.push(
      buildSalesforceRequest(createSalesforceAPI, [
        profileData.responseId,
        "Account",
      ])
    );
    requests.push(
      buildSalesforceRequest(createSalesforceAPI, [
        profileData.responseId,
        "Contact",
      ])
    );
    let results = await executeSalesforceRequests(requests);
    setSalesforceResults(results);
    setIsLoading(false);
  };

  const buildSalesforceRequest = (apiCall, params) => {
    return { apiCall, params };
  };

  const executeSalesforceRequests = async (requests) => {
    const responses = [];
    for (let i = 0; i < requests.length; i++) {
      const request = requests[i];
      try {
        const response = await request.apiCall(...request.params);
        responses.push(response);
      } catch (e) {
        responses.push(e.response.data);
      }
    }
    return resolveSalesforceResponses(responses);
  };

  const resolveSalesforceResponses = (responses) => {
    let results = [];

    responses.forEach((response) => {
      const result = resolveSalesforceResponse(response);
      results.push(result);
    });
    return results;
  };

  const resolveSalesforceResponse = (response) => {
    let result;
    const statusCode = response.statusCode;
    const salesforceObject = response.salesforceObject;
    switch (statusCode) {
      case 201:
        // Created OK with no duplicate warnings
        result = {
          created: true,
          status: "created",
          type: salesforceObject,
          result: response.result,
          statusCode,
          body: response,
        };
        break;
      case 202:
        // Not created, duplicates found
        // Duplicates are a list of lists, [[dupe1], [dupe2]], thanks salesforce
        // We also have matchResults, which contains match certainty
        // First we loop over the certainties and create an object of (objectId, certainty) pairs
        const objectCertainties = {};
        const matchResults = response.result.matchResults[0];
        const matchRecords = matchResults.matchRecords;
        for (let i = 0; i < matchRecords.length; i++) {
          const matchRecord = matchRecords[i];
          objectCertainties[matchRecord.record.Id] = {
            matchCertainty: matchRecord.matchConfidence,
            matchRule: matchResults.rule,
          };
        }
        const duplicates = [];
        response.result.duplicates.forEach((dupe) => {
          let sfDuplicate = dupe[0];
          sfDuplicate = {
            ...sfDuplicate,
            ...objectCertainties[sfDuplicate.Id],
          };
          duplicates.push(sfDuplicate);
        });
        if (response.salesforceObject === "Account") {
          setOrganisationDuplicates(duplicates);
        } else {
          setContactDuplicates(duplicates);
        }
        result = {
          status: "duplicates",
          statusCode,
          created: false,
          type: salesforceObject,
          result: response.result,
          duplicates: duplicates,
          body: response,
        };
        break;
      default:
        console.error(
          "Unknown occurred fetching salesforce response:",
          response
        );
        result = {
          status: "error",
          statusCode,
          created: false,
          type: salesforceObject,
          error: response,
          body: response,
        };
        setIsErrored(true);
        break;
    }
    return result;
  };

  const createSalesforceAPI = async (
    responseId,
    salesforceObject,
    ignoreDuplicates = false
  ) => {
    const payload = {
      responseId,
      salesforceObject,
      ignoreDuplicates,
    };
    // Create object in Salesforce
    return API.post("associates-salesforce-create", "/", { body: payload });
  };
  const updateSalesforceAPI = async (
    responseId,
    salesforceObject,
    salesforceObjectId
  ) => {
    const payload = {
      responseId,
      salesforceObject,
      salesforceObjectId,
    };
    // Update object in Salesforce
    return API.put("associates-salesforce-update", "/", { body: payload });
  };

  const continueSalesforceFlow = async () => {
    setIsSaving(true);
    const requests = [];

    if (createNewOrg) {
      requests.push(
        buildSalesforceRequest(createSalesforceAPI, [
          profileData.responseId,
          "Account",
          true,
        ])
      );
    } else if (selectedOrgId !== null) {
      requests.push(
        buildSalesforceRequest(updateSalesforceAPI, [
          profileData.responseId,
          "Account",
          selectedOrgId,
        ])
      );
    }

    if (createNewContact) {
      requests.push(
        buildSalesforceRequest(createSalesforceAPI, [
          profileData.responseId,
          "Contact",
          true,
        ])
      );
    } else if (selectedContactId !== null) {
      requests.push(
        buildSalesforceRequest(updateSalesforceAPI, [
          profileData.responseId,
          "Contact",
          selectedContactId,
        ])
      );
    }
    let results = await executeSalesforceRequests(requests);
    setSalesforceResults(results);
    setIsSaving(false);
    setAllResolved(true);
  };

  const setSelectedOrgIdHandler = (orgId) => {
    setSelectedOrgId(orgId);
    setCreateNewOrg(false);
  };
  const setSelectedContactIdHandler = (contactId) => {
    setSelectedContactId(contactId);
    setCreateNewContact(false);
  };

  // On modal load, attempt to create the salesforce objects
  useEffect(() => {
    createSalesforceObjects();
  }, []);

  useEffect(() => {
    // If all have been created or updated (ie. resolved), complete the flow
    if (allResolved) {
      onComplete();
    }
  }, [allResolved, onComplete]);

  return (
    <div data-testid="salesforce-entry-selection" className={`${className}`}>
      {isLoading ? (
        <div className="loading-placeholder">
          <h1>Saving to salesforce...please wait</h1>
          <p />
          <LoadingSpinner />
        </div>
      ) : (
        <div>
          {organisationDuplicates.length > 0 || contactDuplicates.length > 0 ? (
            <div>
              <h2>Salesforce duplicates found</h2>
              <p>
                We found existing records in Salesforce, that may be the same as
                the applicant's contact information or organisation. Please
                check carefully the displayed items to see if they are
                duplicates.
              </p>
              <div className="matches">
                {organisationDuplicates.length > 0 ? (
                  <div>
                    <h3>Matching Organisations</h3>
                    <p>
                      Select an existing organisation to update, or ignore the
                      duplicates and create a new organisation
                    </p>
                    <ul className="match-list">
                      {organisationDuplicates.map((org) => (
                        <SalesforceOrganisation
                          key={org.Id}
                          org={org}
                          selected={org.Id === selectedOrgId && !createNewOrg}
                          setSelected={setSelectedOrgIdHandler}
                        />
                      ))}
                      <DataCard
                        key="new-organisation"
                        data={[
                          [
                            {
                              title: "",
                              value: "Create new organisation",
                              type: "text",
                            },
                            {
                              title: "",
                              value:
                                "If you wish to ignore the duplicates, you can override and a new Organisation will be created in salesforce with the details they provided",
                              type: "text",
                            },
                          ],
                        ]}
                        selected={createNewOrg}
                        onClick={() => setCreateNewOrg(true)}
                      />
                    </ul>
                  </div>
                ) : (
                  <div>
                    <h3>
                      The organisation was successfully added to Salesforce
                    </h3>
                  </div>
                )}
                {contactDuplicates.length > 0 ? (
                  <div>
                    <h3>Matching contacts</h3>
                    {contactDuplicates.length === 0 && !isLoading ? (
                      <p>A new contact has been created in Salesforce</p>
                    ) : (
                      <p>
                        Select an existing contact to update, or ignore the
                        duplicates and create a new contact
                      </p>
                    )}
                    <ul className="match-list">
                      {contactDuplicates.map((contact) => (
                        <SalesforceContact
                          key={contact.Id}
                          contact={contact}
                          selected={
                            contact.Id === selectedContactId &&
                            !createNewContact
                          }
                          setSelected={setSelectedContactIdHandler}
                        />
                      ))}
                      <DataCard
                        key="new-contact"
                        data={[
                          [
                            {
                              title: "",
                              value: "Create new contact",
                              type: "text",
                            },
                            {
                              title: "",
                              value:
                                "If you wish to ignore the duplicates, you can override and a new Contact will be created in salesforce with the details they provided",
                              type: "text",
                            },
                          ],
                        ]}
                        selected={createNewContact}
                        onClick={() => setCreateNewContact(true)}
                      />
                    </ul>
                  </div>
                ) : (
                  <div>
                    <h3>The contact was successfully added to Salesforce</h3>
                  </div>
                )}
              </div>
              <LoadingRow isLoading={isSaving}>
                <ReviewControl
                  onCancel={onCancel}
                  onSaveComplete={continueSalesforceFlow}
                  disableSaveComplete={isLoading || !canComplete}
                  status={status}
                />
              </LoadingRow>
            </div>
          ) : isErrored ? (
            <div>
              <h2>An error occurred</h2>
              <p>
                Something went wrong trying to save to salesforce, please review
                the errors. If the issue persists, please contact the digital /
                datasphere team with a screenshot of this error.
              </p>
            </div>
          ) : (
            <div>
              <h2>Successfully added to Salesforce</h2>
              <p>
                The organisation and contact has successfully been added to the
                salesforce database.
              </p>
              <div className="continue-button">
                <Button
                  color={vars.barrowWightGrey}
                  backgroundColor={vars.learningGreen}
                  onClick={() => setAllResolved(true)}
                >
                  Continue
                </Button>
              </div>
            </div>
          )}
          {salesforceResults.length > 0 ? (
            <div>
              <h3>Results</h3>
              <ul className="salesforce-results">
                {salesforceResults.map((salesforceResult) => (
                  <li
                    key={`_salesforce_result_${salesforceResult.result.id}`}
                    data-testid="salesforce-result"
                    className="salesforce-result"
                  >
                    {salesforceResult.statusCode === 200 ||
                    salesforceResult.statusCode === 201 ||
                    salesforceResult.statusCode === 202 ? (
                      <div className="success">
                        <h4>
                          {salesforceResult.type}: {salesforceResult.status}
                        </h4>
                        {salesforceResult.statusCode === 201 ? (
                          <a
                            target="_blank"
                            href={`${process.env.GATSBY_SALESFORCE_URL}/lightning/r/${salesforceResult.salesforceObject}/${salesforceResult.result.id}/view`}
                          >
                            View {salesforceResult.salesforceObject} in
                            Salesforce
                          </a>
                        ) : null}
                      </div>
                    ) : (
                      <div className="error">
                        <h4>{salesforceResult.status}</h4>
                        <p>{JSON.stringify(salesforceResult.error)}</p>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const StyledSalesforceEntrySelection = styled(SalesforceEntrySelection)`
  .loading-placeholder {
    width: 20rem;
    margin: 0 auto;
  }

  .info-text {
    margin-left: 2rem;
    li {
      margin-bottom: 0.25rem;
    }
  }
  .reload-salesforce {
    cursor: pointer;
  }
  .matches {
    margin: 1rem 0;
    display: grid;
    grid-gap: 1rem;
    h3 {
      margin-bottom: 1rem;
    }
  }
  .match-list > div {
    margin: 1rem 0;
  }
  .continue-button {
    display: grid;
    justify-items: end;
    margin: 1rem auto;
  }
  @media screen and (min-width: ${vars.desktopSize}) {
    .matches {
      grid-template-columns: 50% auto;
  }
  .salesforce-result {
    .error h4 {
      color: red;
    }
  }
`;

export default StyledSalesforceEntrySelection;
