import React from "react";
import DataRow from "src/design-system/components/data-row/data-row";
import vars from "src/design-system/variables";
import { formatDate } from "src/utils/dateHelpers";

const OverviewSurveyRow = ({ data }) => (
  <DataRow columnWidths={vars.reviewAssociateColumnWidths}>
    <div>{data.companyName}</div>
    <div>{data.associateType}</div>
    <div>{formatDate(data.recordedDate)}</div>
  </DataRow>
);

export default OverviewSurveyRow;
