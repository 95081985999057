import React from "react";
import styled from "@emotion/styled/macro";
import vars from "src/design-system/variables";

const DataCard = ({ className, data, selected, onClick }) => {
  const ensureUrlProtocol = (url) => {
    let pattern = /^((http|https):\/\/)/;
    return !pattern.test(url) ? "https://" + url : url;
  };
  return (
    <div data-testid="data-card" className={className} onClick={onClick}>
      <div data-testid="data-card-item" className={`item ${selected ? "selected" : ""}`}>
        {data.map((column, index) => (
          <div key={index} className="column">
            {column.map((field) => (
              <div key={field.title + Math.random()} className="field">
                {field.type === "text" ? (
                  <>
                    <div className="field-title">{field.title}</div>
                    <div className="field-value">
                      {field.value !== null ? field.value : "None"}
                    </div>
                  </>
                ) : (
                  <div className="field-value">
                    <div className="field-title">{field.title}</div>
                    <a
                      target="_blank"
                      href={ensureUrlProtocol(field.value)}
                      role="link"
                      rel="noopener"
                    >
                      {field.display ? field.display : field.value}
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const StyledDataCard = styled(DataCard)`
  .item {
    display: flex;
    border: 2px solid ${vars.gandalfGrey};
    border-radius: 5px;
    padding: 0.5rem 1rem;
    transition: 0.2s background-color;
    &:hover {
      background-color: ${vars.gandalfGrey};
      cursor: pointer;
    }
  }
  .column {
    margin-right: 1.5rem;
  }
  .field-value {
    word-wrap: break-word;
  }
  .item.selected {
    background-color: ${vars.circulyticsGrey};
    transition: 0.2s background-color;
    &:hover {
      background-color: ${vars.circulyticsGrey};
    }
    .field-value {
      color: white;
    }
    a {
      color: white;
      text-decoration: underline;
    }
  }
  .field {
    margin: 0.5rem 0;
  }
  .field-title {
    color: ${vars.grimboldGrey};
    font-size: 0.75rem;
  }
`;

export default StyledDataCard;
